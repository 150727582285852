import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useParams } from "react-router-dom";
import 'react-awesome-slider/dist/styles.css';
import HashLoader from "react-spinners/ClipLoader";
import Carousel from "nuka-carousel";

import {isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet} from 'react-device-detect';

import {PullToRefresh, PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";

// Assuming logo.png is in the same folder as JS file
// import playIcon from '../assets/icon/play.png';
// import pauseIcon from '../assets/icon/pause.png';
// import playIconNew from '../assets/icon/ic_vedio_play_x.png';
// import logo from '../assets/img/logo-1.png';
// import KUDO_URL from '../assets/icon/kudos_gray.png';
import KUDO_LINE_URL from '../assets/icon/Kudo/kudos_line.imageset2/kudos_line.png';
import KUDO_FILL_URL from '../assets/icon/Kudo/kudo_fill.imageset2/kudo_fill.png';
import SAY_URL from '../assets/icon/Message/say_line.imageset2/say_line.png';
import SPREAD_URL from '../assets/icon/Spread/spread_line.imageset2/spread_line.png';
import MILES_URL from '../assets/icon/ic_travelbuddy_new_x.png';
import cobaltLevelIcon from '../assets/icon/Explurger Level/Cobalt Level/cobalt.imageset/cobalt@2x.png';
import silverLevelIcon from '../assets/icon/Explurger Level/Silver Level/silver.imageset/silver@2x.png';
import goldLevelIcon from '../assets/icon/Explurger Level/Gold Level/gold.imageset/gold@2x.png';
import spectraLevelIcon from '../assets/icon/Explurger Level/Spectra/platinum.imageset/platinum@2x.png';
import primeLevelIcon from '../assets/icon/Explurger Level/Prime/prime.imageset/prime@3x.png';

import eyeFillIcon1x from '../assets/icon/eye_fill@1x.png';
//import crossIcon3x from '../assets/icon/icone-x-bleue.png';

import splashScreen from '../assets/img/sonu-sood.jpeg';

import * as constants from '../constants';

import videojs from 'video.js';
// import VideoJSExplr from './VideoJSExplr';
import CropImage from './CropImage';
import CropImagePlacePhoto from './CropImagePlacePhoto';
import CropProfileImage from './CropProfileImage';
//import CommonComponent from './CommonComponent';

import useWindowDimensions from '../hooks/useWindowDimensions';
import ReactHtmlParser from 'react-html-parser';
import {localStorageTTL, getLocalStorage, setLocalStorage} from '../hooks/localStorageWithTTL';

//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import moment from"moment";

// Post_Type  Spread_Type	Post Name        Post_ID
//     0	      0	        Explurged In      44824 / 44827- with media
//     1	      0	        Pictures/Videos   44117/44115
//     2	      0	        Travelling to     
//     3	      0	        Will go to        44110
//     4	      0	        along with        44121/44122
//     5	      0	        Says              44120/44105/44119
//     6	      0	        journey so far    44097/44096/44094/44092/44091
//     7	      0	        Profile Picture   44103
//     8	      0	        Cover Picture     44104
//     9	      0	        Link Preview      44126/44127/44125

// 44210 spreded

// Nigam Kr - 22a9a9b6e3ea868aa64b3e539d4f440e
// Beauty - k76he9z4sjas58qq3c5l3jqgmpw1653371096324

export const ViewPost = (props) => {
//export default function ViewPost(props) {
    //const pullToRefreshtriggerHeight = "100";
    const { id } = useParams();
    const [postData, setPostData] = useState([]);
    const [postCreatorInfo, setPostCreatorInfo] = useState([]);
    const [isActive, setIsActive] = useState(true);
    const [isHovering, setIsHovering] = useState(false);
    const {width, height} = useWindowDimensions();

    const {attr_id, sendDataToParent, dontDismissModelHide, resfreshAllowed} = props; 

    const [userId, setUserId] = useState(id);

    const maxBoxWidth1024 = 1024;
    const maxBoxWidth768 = 768;
    const maxBoxWidth = 425;
    const maxBoxWidth426 = 426;
    const maxBoxPadding = 4;
    const maxBoxPaddingWidthFull = 3;
    const maxBoxPaddingHeightFull = 0;

    const postCountMaxModelSet = 3;
    const postCountMaxModelTimes = 3;
    const [fullProfileInAppModalClose, setFullProfileInAppModalClose] = useState(0); 
    var previousP = -1; 

    const postCountMaxModel = 10;
    const timeoutBottomModel = 60 * 1000; // in milliseconds (60 Seconds)
    const postSlideMaxModel = 3;
    const timeoutDontDismissModel = 30 * 1000; // in milliseconds (30 Seconds)

    const bottomModelHide = useRef(true);
    //const dontDismissModelHide = useRef(true);
    const wScrollYPosition = useRef(0);
    const wScrollYPositionEnd = useRef(0);
    const initialized = useRef(false);
    const [modalBox, setModalBox] = React.useState({
        showModal:false,
        slideIndex:-1,
        modalPost:null
    });
    const [modalBox2, setModalBox2] = React.useState({
        showModal:false,
        slideIndex:-1,
        modalPost:null
    });
    
    //const BottomModalOpen = CommonComponent.BottomModalOpen;
    //const clickCenterModalOpen = CommonComponent.clickCenterModalOpen;


    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) { 
       
            // Generate random number 
            var j = Math.floor(Math.random() * (i + 1));
                       
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
           
        return array;
    }
    

    function handleDataToParent(data) {

        setPostCreatorInfo(data);
        sendDataToParent(data);
    }

    // function handleDataToParentDontDismissModelHide(data) {
    //     sendDataToParentDontDismissModelHide(data);
    // }

    useEffect(() => {
        //console.log("width, height-", width, height);
        if(attr_id != undefined && attr_id != null)
            setUserId(attr_id);
    //}, [width, height]);
    }, []);    

    const redirctToAppOrWebsite = () => {
        //isMobile, isAndroid, isIOS, isWinPhone
        //console.log("redirctToAppOrWebsite-",isDesktop, isMobile, isAndroid, isIOS, deviceType, isTablet);
        var url = constants.APP_WEBSITE_URL;       
        if((isMobile || isTablet) && isAndroid)
        {
            url = constants.APP_GOOGLE_PLAY_URL;
        }
        else if((isMobile || isTablet) && isIOS)
        {
            url = constants.APP_APPLE_APP_STORE_URL;
        }             
        //console.log("redirctToAppOrWebsite-",url);   
        //window.open(url);
        window.location.replace(url);
    }    
      
    var setFetchedPostData = async (postDataReversed) => {
        //console.log("setFetchedPostData ", postDataReversed);

        //console.log("resfreshAllowed ", resfreshAllowed);
        
        // check user blocked/deleted/de-active
        if(
            resfreshAllowed == 1 
        ) 
        {
            var postData = (postDataReversed && postDataReversed[0])?postDataReversed[0]:null;
            //console.log("postData ", postData);
            var User_Status = postData?.User_Status;
            var Block_Status = postData?.Block_Status;    
            //console.log("User_Status ", User_Status);
            //console.log("Block_Status ", Block_Status);
            if(
                (User_Status != undefined && User_Status == "1")  //user deleted/deactive
                || 
                (Block_Status != undefined && Block_Status != "0") //user blocked
            )
            {        
                // do redirect to website/app/app store
                redirctToAppOrWebsite();
                return;
            } 
        }

        setPostData(postDataReversed);
        if (postDataReversed[0].Shared_Post_Owner_Name) {
            var Creater_Name = postDataReversed[0].Shared_Post_Owner_Name;
            var Creater_ProfilePic = postDataReversed[0].Shared_Post_Owner_Thumb_ProfilePic;
        } else {
            var Creater_Name = postDataReversed[0].Creater_Name;
            var Creater_ProfilePic = postDataReversed[0].Creater_Thumb_ProfilePic;
        }   

        handleDataToParent({ Creater_Name: Creater_Name, Creater_ProfilePic: Creater_ProfilePic });

        setIsActive(false);  // Loader false

        //localStorageTTL, getLocalStorage, setLocalStorage

        setLocalStorage('post_data', postDataReversed, localStorageTTL);

    }

    var noPosts = async () => {
        setIsActive(false);  // Loader false
    }


    var getPostData = async (title, body) => {
        try {
            // console.log("-----getPostData--------------------------");
            // console.log(constants);
            // console.log(process.env);
            var body = {
                "User_TokenKey": userId
            }
            await fetch(constants.API_BASE_URL + constants.userPostList, {
                method: 'POST',
                body: JSON.stringify(body),
                title: title,
                reqId: Math.random().toString(36).slice(2),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
            .then((response) => response.json())
            .then((data) => {   
                var d = 0;             
                if(data.status && data.code == 200)
                {
                    var postData = data.data;
                    if(postData != undefined && postData != null && postData.length >0)
                    {
                        d = 1;
                        //const postDataReversed = postData.reverse();  
                        //const postDataReversed = shuffleArray(postData);  
                        const postDataReversed = postData;                     
                        setFetchedPostData(postDataReversed);
                    }
                }   
                if(d == 0)
                {
                    noPosts();
                }                 
            })
            .catch((error) => {
                console.log("PostList-getPostData-",error);
                noPosts();
            });
        }
        catch (e) {
            console.log("e ", e)
            setPostData([])
        }
    }
    // top- 85 left- 0 bottom- 409.3984375 right- 360 innerHeight- 668 innerWidth- 360
    // top- 282.68182373046875 left- 0 bottom- 856.0454711914062 right- 393.0909118652344 innerHeight- 746 innerWidth- 393
    const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
        //console.log("elementIsVisibleInViewport-",el, partiallyVisible);
        if(el == undefined) return false;
        const { top, left, bottom, right } = el.getBoundingClientRect();
        const { innerHeight, innerWidth } = window;
        //console.log("top-",top, "left-",left, "bottom-",bottom, "right-",right, "innerHeight-",innerHeight, "innerWidth-",innerWidth);
        return partiallyVisible
          ? ((top >= 0 && top < innerHeight) || (bottom > 0 && (bottom-1) <= innerHeight)) &&
              ((left >= 0 && left < innerWidth) || (right > 0 && (right-1) <= innerWidth))
          : top >= 0 && left >= 0 && (bottom-1) <= innerHeight && (right-1) <= innerWidth;
    };
      
    //   // e.g. 100x100 viewport and a 10x10px element at position
    //   // {top: -1, left: 0, bottom: 9, right: 10}
    //   elementIsVisibleInViewport(el); // false - (not fully visible)
    //   elementIsVisibleInViewport(el, true); // true - (partially visible)    

    const handleScroll = (event) => {
        const newScrollYPosition = window.pageYOffset;
        //console.log("newScrollYPosition-", wScrollYPosition.current, newScrollYPosition, event, event.target.offsetTop);
        wScrollYPosition.current = newScrollYPosition;

        //const element = document.getElementById('my-container-element');
        //var boxRect = element.querySelector(".block-box").getBoundingClientRect();
        //var postHeight = boxRect.height;

        //var visiblePost  = Math.floor(( (newScrollYPosition-100) / (postHeight + 5) ) + 1);


        var collection = document.getElementsByClassName("block-box post-box");
        //console.log("collection", collection);
        //collection.each((item, index) => {
            //console.log(index, item, elementIsVisibleInViewport(item,true));
        //});
        var i, p = 0, v=false;        
        for(i=0; i < collection.length; i++ )
        {
            //console.log((i+1), collection[i], elementIsVisibleInViewport(collection[i]));
            v = elementIsVisibleInViewport(collection[i]);

            if(v == true)
            {
                p = (i+1);
            }
            if(!v && p>0)
            {
                break;
            }
        }
        //console.log((p), collection[p-1], elementIsVisibleInViewport(collection[p-1]));

        //console.log("ViewPost-handleScroll-","visiblePost-",visiblePost,"postCountMaxModel-",postCountMaxModel,"bottomModelHide.current-",bottomModelHide.current);

        var quotient = Math.floor(p/postCountMaxModelSet);
        var remainder = p % postCountMaxModelSet;

        var quotient1 = Math.floor(quotient/postCountMaxModelTimes);
        var remainder2 = quotient % postCountMaxModelTimes;        
        // fullProfileInAppModalClose

        //console.log("previousP-",previousP, "p-",p, "postCountMaxModelSet-",postCountMaxModelSet,"quotient-", quotient, "remainder-",remainder, "quotient1-",quotient1, "remainder2-",remainder2);

        // if(visiblePost < postCountMaxModel)
        // {
        //     BottomModalOpen('none');
        // }
        // else
        // {
        //     if(bottomModelHide.current)
        //         BottomModalOpen('block');
        //     else
        //         BottomModalOpen('none');
        // }

        if(p==0)
        {
            return;
        }

        if(p < postCountMaxModelSet)
        {
            previousP = -1;
        }

        if(p < postCountMaxModelSet)
        {
            ///BottomModalOpen('none');

            //console.log("------------------1-");
        }
        else
        {
            //console.log("------------------2-");
            if(previousP != p && quotient > 0 && remainder == 0)
            {
                BottomModalOpen('block');
                previousP = p;                
            }
            else
            {
                //BottomModalOpen('none');
            }
        }
    };

    const handleScrollEnd = (event) => {
        const newScrollYPosition = window.pageYOffset;
        var pdif = wScrollYPositionEnd.current - newScrollYPosition;
        //console.log("handleScrollEnd-", pdif, wScrollYPositionEnd.current, newScrollYPosition, event, event.target.offsetTop);
        wScrollYPositionEnd.current = newScrollYPosition;

        // element.scrollIntoView(align)

    };    

    const handleRefresh = () => {
        ///console.log("handleRefresh-");
        var body = {
            "User_TokenKey": userId
        }
        return handleRefreshNew("Post Details", body);
        //console.log("handleRefresh-2");
    };

    const onRefreshNew = () => {
        //console.log("onRefreshNew Simulates a delay of 2 seconds-1");
        return new Promise((resolve) => {
            //console.log("onRefreshNew Simulates a delay of 2 seconds-2");
            setTimeout(() => {
                //console.log("onRefreshNew Simulates a delay of 2 seconds-2-resolve");
                resolve();
                //console.log("onRefreshNew Simulates a delay of 2 seconds-3-resolve-after");
            //}, 2000); // Simulates a delay of 2 seconds
            }, 100); // Simulates a delay of 1 seconds
            //console.log("onRefreshNew Simulates a delay of 2 seconds-2------------------2-");
        });
        //console.log("onRefreshNew Simulates a delay of 2 seconds-2-------------1-");
    };
    
    const handleRefreshNew = (title, body) => {
        //console.log("handleRefreshNew-1");
        return onRefreshNew()
            .then(() => {
                //console.log("handleRefreshNew-2");
                //return fetch('https://jsonplaceholder.typicode.com/posts/1'); // Example API
                return fetch(constants.API_BASE_URL + constants.userPostList, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    title: title,
                    reqId: Math.random().toString(36).slice(2),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                });
            })
            .then((response) => {
                //console.log("handleRefreshNew-3");
                //console.log(response);
                if (!response.ok) {
                    console.error('Network response was not ok');
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                //console.log("handleRefreshNew-4");
                //console.log(data);
                //setData(data);
                //setLoading(false);
                var d = 0;             
                if(data.status && data.code == 200)
                {
                    var postData = data.data;
                    if(postData != undefined && postData != null && postData.length >0)
                    {
                        d = 1;
                        //const postDataReversed = postData.reverse();  
                        //const postDataReversed = shuffleArray(postData);  
                        const postDataReversed = postData;                     
                        setFetchedPostData(postDataReversed);
                    }
                }   
                if(d == 0)
                {
                    noPosts();
                }
            })
            .catch((error) => {
                //setError(error.message);
                //setLoading(false);
                // console.log("handleRefreshNew-error-5");
                // console.log("Refresh-PostList-getPostData-",error.message);
                // console.log(error);
                noPosts();
            });
    };    
   

    useEffect(() => {

        if (!initialized.current) {
            initialized.current = true; 

            if(window.location.hostname == 'localhost' && 1==0)
            {
                var postDataReversed = getLocalStorage('post_data');
          
                if(postDataReversed == null)
                {
                    getPostData("Post Details", {}); 
                }
                else
                {
                    setFetchedPostData(postDataReversed);
                }  
            }
            else
            {
                getPostData("Post Details", {}); 
            }         
        }
            
        window.addEventListener('scroll', handleScroll, { passive: true });

        window.addEventListener('scrollend', handleScrollEnd, { passive: true });

        return () => { 
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scrollend', handleScrollEnd);
        };            

    }, []);

    function DateFormater(date) {
        if (date) {
            var today = new Date(date);
            var ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(today);
            var mo = new Intl.DateTimeFormat("en", { month: "short" }).format(today);
            var da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(today);
            var Post_Date = da + " " + mo + " " + ye
        } else {
            var Post_Date = "";
        }
        return Post_Date;
    }

    function postTimeView(date) {
        let post_time = "";
        // moment.utc(time).local()
        //var post_time2 = moment.utc(date).local().fromNow(); //eg. 1 day, 2 hours
        // days, hours, minutes, and seconds
        var b = moment.utc(date).local();
        var a = moment();
        var days = a.diff(b, 'days'); // days
        var hours = a.diff(b, 'hours'); // hours
        var minutes = a.diff(b, 'minutes'); // minutes
        var seconds = a.diff(b, 'seconds'); // seconds

        days = parseInt(days);
        hours = parseInt(hours);
        minutes = parseInt(minutes);
        seconds = parseInt(seconds);

        if (seconds <= 59) {
            post_time = "Just now";
        } else if (minutes == 1) {
            post_time = (minutes) + " min ago";            
        } else if (minutes > 1 && minutes <= 59) {
            post_time = (minutes) + " mins ago";
        } else if (hours == 1) {
            post_time = (hours) + " hour ago";             
        } else if (hours > 1 && hours <= 24) {
            post_time = (hours) + " hours ago";  
        } else if (days == 1) {
            post_time = (days) + " day ago";                     
        } else if (days > 1 && days <= 6) {
            post_time = (days) + " days ago";
        } else {
            post_time = DateFormater(date);
        }

        //console.log("post_time-",post_time, "post_time2-",post_time2, "days-",days, "hours-",hours, "minutes-",minutes, "seconds-",seconds);

        return post_time;
    }

    function postTimeViewBucket(date) {
        let post_time = "";
        // moment.utc(time).local()
        //var post_time2 = moment.utc(date).local().fromNow(); //eg. 1 day, 2 hours
        // years, months, weeks, days, hours, minutes, and seconds
        var b = moment.utc(date).local();
        var a = moment();
        var months = a.diff(b, 'months'); // months
        var days = a.diff(b, 'days'); // days
        var hours = a.diff(b, 'hours'); // hours
        var minutes = a.diff(b, 'minutes'); // minutes
        var seconds = a.diff(b, 'seconds'); // seconds

        months = parseInt(months);
        days = parseInt(days);
        hours = parseInt(hours);
        minutes = parseInt(minutes);
        seconds = parseInt(seconds);

        if (seconds <= 59) {
            post_time = "Just now";
        } else if (minutes == 1) {
            post_time = (minutes) + " min ago";            
        } else if (minutes > 1 && minutes <= 59) {
            post_time = (minutes) + " mins ago";
        } else if (hours == 1) {
            post_time = (hours) + " hour ago";             
        } else if (hours > 1 && hours <= 24) {
            post_time = (hours) + " hours ago";  
        } else if (days == 1) {
            post_time = (days) + " day ago";
        } else if (days > 1 && hours <= 30) {
            post_time = (days) + " days ago";
        } else if (months == 1) {
            post_time = (months) + " month ago";
        } else if (days > 1 && hours <= 30) {
            post_time = (months) + " months ago";            
        } else {
            post_time = DateFormater(date);
        }

        //console.log("post_time-",post_time, "post_time2-",post_time2, "days-",days, "hours-",hours, "minutes-",minutes, "seconds-",seconds);

        return post_time;
    }    

    function postTimeViewUpdated(post) {
        let post_time = "";

        if(post.Post_Updated_Status == "0")
            return (<></>);

        let date = post.Updated_DateTime;

        var today = new Date();
        var localoffset = (today.getTimezoneOffset() / 60);
        var dz1 = new Date(new Date(date).getTime());
        var dz2 = new Date(new Date().getTime());
        let post_diff = Math.abs(dz2.getTime() - dz1.getTime()); // milliseconds 
        let pt = parseInt(post_diff / (1000 * 60 * 60 * 24)); // days
        let pt_hour = parseInt(post_diff / (1000 * 60 * 60)) + localoffset; 
        let pt_min = parseInt(post_diff / (1000 * 60)) + (localoffset * 60);
        let pt_sec = parseInt(post_diff / 1000) + (localoffset * 60 * 60);
        if (pt_sec <= 59) {
            post_time = "Just now";
        } else if (pt_min >= 1 && pt_min <= 59) {
            post_time = parseInt(pt_min) + " min ago";
        } else if (pt_hour >= 1 && pt_hour <= 24) {
            post_time = parseInt(pt_hour) + " hour ago";
        } else if (pt <= 6) {
            post_time = parseInt(pt) + " day ago";
        } else {
            post_time = DateFormater(date);
        }
        //console.log("post_diff-",post_diff,"pt-",pt,"pt_hour-",pt_hour,"pt_min-",pt_min,"pt_sec-",pt_sec);
        //console.log("post_time-",post_time);
        //return post_time
 
        return(<>
            <span className="date-color time-dot"></span>
            <span className="date-color"> Updated {(post_time)}</span>             
        </>);
        
    }    

    // postData.Post_Type == "2"
    function postString2(post) {
        if (post.Post_Type == "2") {
            return (<>
                <span> from <b>{post.From_PlaceName}</b> by <b>{post.User_Travel_By}</b></span>
            </>)
        }
    }

    function postString3(post) {

        var pt = post.Post_String.toString();

        // Post_Type : "7"
        if (post.Post_Type == constants.POST_TYPE_PROFILE_PIC_STR) {
            return (<>
                {/* <b>{post.Post_String2}</b>  */}
                {post.Post_String}
            </>)
        }
        // Post_Type : "6"
        else if (post.Post_Type == constants.POST_TYPE_SPREAD_STR) 
        {
            // Spread_Type : "6"
            // Spread_Type : "4"
            if (post.Spread_Type == constants.POST_SPREAD_TYPE_KUDOS || post.Spread_Type == constants.POST_SPREAD_TYPE_CITY)
            {                
                var psts = post.Post_String2.toString().split(',');
                for(var i=0; i<psts.length;i++)
                {
                    pt = pt.replace(psts[i].toString(), "<b>" + psts[i].toString() + "</b>");
                }
                return (<>
                    {ReactHtmlParser(pt)}
                </>)
            } 
            // // Spread_Type : "7"
            // else if (post.Spread_Type == constants.POST_SPREAD_TYPE_FELLOW)
            // {

            // }
            // Spread_Type : "8"
            else if (post.Spread_Type == constants.POST_SPREAD_TYPE_EXPLURGER_LEVEL)
            {
                // Post_String                :                 "is now on Cobalt Level"
                // Post_String2                :                 "Explurger Level Summery"
                var pt1 = pt.replace('is now on', '').trim(); 
                pt = 'is now on ' + "<b>" + pt1 + "</b>";
                return (<>
                    {ReactHtmlParser(pt)}
                </>)                
            }
            //Spread_Type : "0"
            else if (post.Spread_Type == constants.POST_SPREAD_TYPE_EXPLURGER)
            {
                // Post_String                :                 "has spread Explurger Journey So Far"
                // Post_String2                :                 ""
                var pt1 = pt.replace('has spread', '').trim(); 
                pt = 'has spread ' + "<b>" + pt1 + "</b>";
                return (<>
                    {ReactHtmlParser(pt)}
                </>)                
            }            
            else
            {
                return (<>
                    {post.Post_String}
                </>)                
            }
        }
        // // Post_Type : "2"
        // else if (post.Post_Type == constants.POST_TYPE_TRAVELLING_STR) 
        // {

        // }
        else
        {
            return (<>
                {/* <b>{post.Post_String2}</b>*/}
                {post.Post_String}
            </>)
        }
        //*/
    }

    function FutureTravelDateView(post) {
        // console.log("FutureTravelDateView ", post.Future_Travel_Date)
        if (post.Future_Travel_Date && post.Future_Travel_Date != "0000-00-00 00:00:00") {
            return (<> on <b> {DateFormater(post.Future_Travel_Date)}</b></>)
        }
    }

    const postProfileView = (post) => {
        // Badge icon
        var badgeImg = "";
        var Badge_Img = "";
        if (post.Profile_Type == "1" && post.Badge_Status == "1") {
            badgeImg = constants.ADMIN_BASE_URL + "images/business_badge@2x.png"
            Badge_Img = <img className="badg-verified" src={badgeImg}></img>
        } else if (post.Profile_Type == "0" && post.Badge_Status == "1") {
            badgeImg = constants.ADMIN_BASE_URL + "images/verified-icon.png"
            Badge_Img = <img className="badg-verified" src={badgeImg}></img>
        } 

        // Alog with
        if (post.Tagged_User_Name) {
            var Post_Tagged_Text = " along with ";
        } else {
            var Post_Tagged_Text = "";
        }

        if (post.Post_Shared == "0") {
            return (<>
                <div className="row">
                    <div className="col-sm-1 img_data">
                        <svg viewBox="0 0 100 100" className="image_profile">
                            <defs>
                                <pattern id={"Simg1-"+post.Post_ID} patternUnits="userSpaceOnUse" width="100" height="100">
                                    <image href={post.Creater_Thumb_ProfilePic} width="100" height="100">
                                    </image>
                                </pattern>
                            </defs>
                            <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={"url(#Simg1-"+post.Post_ID+")"} className="strokclass">  </polygon>
                        </svg>
                    </div>
                    <div className="col-sm-10 data-match">
                        <span className="data_left">
                            <strong>{post.Creater_Name}&nbsp;</strong>
                            {Badge_Img}
                        </span>
                        <span > ({post.Miles_Explurged} Miles <img className='milesImg' src={MILES_URL}></img>)</span>
                        <span className="postString">{postString3(post)}</span>
                        <span className="placeName"><b> {post.To_PlaceName}</b></span>
                        <span className='placeName'>{FutureTravelDateView(post)}</span>
                        <span className='placeName'>{postString2(post)}</span>
                        <span className='placeName'>{Post_Tagged_Text}</span>
                        <span className='tagedText'><b> {post.Tagged_User_Name}</b></span>
                        <br></br>
                        {/* <span className="date-color">{DateFormater(post.Created_DateTime)}</span> */}
                        <span className="date-color">{postTimeView(post.Created_DateTime)}</span>

                        {/* {postTimeViewUpdated(post)} */}

                    </div>
                    <div className="col-sm-1 data-match-dot-dot" onClick={clickCenterModalOpen}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </>)
        } else if (post.Post_Shared == "1") {
            {/* spreaded profile */ }
            return (<>
                <div className="row">
                    <div className="col-sm-1 img_data"><svg viewBox="0 0 100 100" className="image_profile">
                            <defs>
                                <pattern id={"Simg1-"+post.Post_ID} patternUnits="userSpaceOnUse" width="100" height="100">
                                    <image href={constants.BUCKET_URL + post.Shared_Post_Owner_ProfilePic} width="100" height="100">
                                    </image>
                                </pattern>
                            </defs>
                            <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={"url(#Simg1-"+post.Post_ID+")"} className="strokclass">  </polygon>
                        </svg>
                    </div>
                    <div className="col-sm-10  data-match">
                        <span className="data_left">Spread by 
                            <strong> {post.Shared_Post_Owner_Name}&nbsp;</strong>
                            {Badge_Img}
                        </span>
                        <span > ({post.Shared_User_Miles_Explurge} Miles <img className='milesImg' src={MILES_URL}></img>)</span>

                        <br></br>
                        {/* <span className="date-color">{DateFormater(post.Shared_Post_Created_DateTime)}</span> */}
                        <span className="date-color">{postTimeView(post.Shared_Post_Created_DateTime)}</span>
                        {/* {postTimeViewUpdated(post)} */}
                    </div>
                    <div className="col-sm-1 data-match-dot-dot" onClick={clickCenterModalOpen}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="row postWoner">
                    <div className="col-sm-1 img_data"><svg viewBox="0 0 100 100" className="image_profile">
                        <defs>
                            <pattern id={"Simg2-"+post.Post_ID} patternUnits="userSpaceOnUse" width="100" height="100">
                                <image href={post.Creater_Thumb_ProfilePic} width="100" height="100">
                                </image>
                            </pattern>
                        </defs>
                        <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={"url(#Simg2-"+post.Post_ID+")"} className="strokclass">  </polygon>
                    </svg>
                    </div>
                    <div className="col-sm-11  data-match">
                        <span className="data_left">
                            <strong>{post.Creater_Name}&nbsp;</strong>
                            {Badge_Img}
                        </span>
                        <span > ({post.Miles_Explurged} Miles <img className='milesImg' src={MILES_URL}></img>)</span>
                        <span className="postString">{post.Post_String}</span>
                        <span className="placeName"><b> {post.To_PlaceName}</b></span>
                        <br></br>
                        {/* <span className="date-color">{DateFormater(post.Created_DateTime)}</span> */}
                        <span className="date-color">{postTimeView(post.Created_DateTime)}</span>
                    </div>
                </div>


            </>)
        } else {
            return (<></>)
        }
    }


    const postProfileView2 = (post,md=0) => {
        // Badge icon
        var badgeImg = "";
        var Badge_Img = "";        
        if (post.Profile_Type == "1" && post.Badge_Status == "1") {
            badgeImg = constants.ADMIN_BASE_URL + "images/business_badge@2x.png"
            Badge_Img = <img className="badg-verified" src={badgeImg}></img>
        } else if (post.Profile_Type == "0" && post.Badge_Status == "1") {
            badgeImg = constants.ADMIN_BASE_URL + "images/verified-icon.png"
            Badge_Img = <img className="badg-verified" src={badgeImg}></img>
        } 

        // Alog with
        if (post.Tagged_User_Name) {
            var Post_Tagged_Text = " along with ";
        } else {
            var Post_Tagged_Text = "";
        }

        if (post.Post_Shared == "0") {
            return (<>
                <div className="row">
                    <div className="col-sm-1 img_data">
                        <svg viewBox="0 0 100 100" className="image_profile">
                            <defs>
                                <pattern id={"Simg1-"+post.Post_ID} patternUnits="userSpaceOnUse" width="100" height="100">
                                    <image href={post.Creater_Thumb_ProfilePic} width="100" height="100">
                                    </image>
                                </pattern>
                            </defs>
                            <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={"url(#Simg1-"+post.Post_ID+")"} className="strokclass">  </polygon>
                        </svg>
                    </div>
                    <div className="col-sm-10 data-match">
                        <span className="data_left">
                            <strong>{post.Creater_Name}&nbsp;</strong>
                            {Badge_Img}
                        </span>
                        <span > ({post.Miles_Explurged} Miles <img className='milesImg' src={MILES_URL}></img>)</span>
                        <span className="postString">{postString3(post)}</span>
                        <span className="placeName"><b> {post.To_PlaceName}</b></span>
                        <span className='placeName'>{FutureTravelDateView(post)}</span>
                        <span className='placeName'>{postString2(post)}</span>
                        <span className='placeName'>{Post_Tagged_Text}</span>
                        <span className='tagedText'><b> {post.Tagged_User_Name}</b></span>
                        <br></br>
                        {/* <span className="date-color">{DateFormater(post.Created_DateTime)}</span> */}
                        <span className="date-color">{postTimeView(post.Created_DateTime)}</span>
                        {/* {postTimeViewUpdated(post)} */}
                    </div>
                    <div className="col-sm-1 data-match-dot-dot" onClick={clickCenterModalOpen}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </>)
        } else if (post.Post_Shared == "1") {
            {/* spreaded profile */ }
            if(md==0)
            {
                return (<>
                    <div className="row">
                        <div className="col-sm-1 img_data"><svg viewBox="0 0 100 100" className="image_profile">
                                <defs>
                                    <pattern id={"Simg1-"+post.Post_ID} patternUnits="userSpaceOnUse" width="100" height="100">
                                        <image href={constants.BUCKET_URL + post.Shared_Post_Owner_ProfilePic} width="100" height="100">
                                        </image>
                                    </pattern>
                                </defs>
                                <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={"url(#Simg1-"+post.Post_ID+")"} className="strokclass">  </polygon>
                            </svg>
                        </div>
                        <div className="col-sm-10  data-match">
                            <span className="data_left">Spread by 
                                <strong> {post.Shared_Post_Owner_Name}&nbsp;</strong>
                                {Badge_Img}
                            </span>
                            <span > ({post.Shared_User_Miles_Explurge} Miles <img className='milesImg' src={MILES_URL}></img>)</span>
    
                            <br></br>
                            {/* <span className="date-color">{DateFormater(post.Shared_Post_Created_DateTime)}</span> */}
                            <span className="date-color">{postTimeView(post.Shared_Post_Created_DateTime)}</span>
                            {/* {postTimeViewUpdated(post)} */}
                        </div>
                        <div className="col-sm-1 data-match-dot-dot" onClick={clickCenterModalOpen}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </>)
            }
            else
            {
                // Shared_Post_Parent_ID : "46936"
                return (<>
                    <div className="row postWoner p010">
                        <div className="col-sm-1 img_data"><svg viewBox="0 0 100 100" className="image_profile">
                            <defs>
                                <pattern id={"Simg2-"+post.Post_ID} patternUnits="userSpaceOnUse" width="100" height="100">
                                    <image href={post.Creater_Thumb_ProfilePic} width="100" height="100">
                                    </image>
                                </pattern>
                            </defs>
                            <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={"url(#Simg2-"+post.Post_ID+")"} className="strokclass">  </polygon>
                        </svg>
                        </div>
                        <div className="col-sm-11  data-match">
                            <span className="data_left">
                                <strong>{post.Creater_Name}&nbsp;</strong>
                                {Badge_Img}
                            </span>
                            <span > ({post.Miles_Explurged} Miles <img className='milesImg' src={MILES_URL}></img>)</span>
                            {/* <span className="postString">{post.Post_String}</span>
                            <span className="placeName"><b> {post.To_PlaceName}</b></span> */}

                            <span className="postString">{postString3(post)}</span>
                            <span className="placeName"><b> {post.To_PlaceName}</b></span>
                            <span className='placeName'>{FutureTravelDateView(post)}</span>
                            <span className='placeName'>{postString2(post)}</span>
                            <span className='placeName'>{Post_Tagged_Text}</span>
                            <span className='tagedText'><b> {post.Tagged_User_Name}</b></span>

                            <br></br>
                            {/* <span className="date-color">{DateFormater(post.Created_DateTime)}</span> */}
                            <span className="date-color">{postTimeView(post.Created_DateTime)}</span>
                        </div>
                    </div>
                </>)
            }

        } else {
            return (<></>)
        }
    }    

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
    const linkRegexHttpWww = /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g;
    const hasHashRegex = /(?:^|\W)#(\w+)(?!\w)/g;
    const tagHashRegex = /(?:^|\W)@(\w+)(?!\w)/g;
    const regexNewLineCount = /$/mg;

    function replacer(matched) {
        let withProtocol = matched
        
        if(!withProtocol.startsWith("http")) {
          withProtocol = "http://" + matched
        }
       
        const newStr = `<a className="text-link" href="${withProtocol}" target="_blank" >${matched}</a>`
        
        return newStr;
    }    

    function replacerHttpWww(matched, p1, p2, p3, offset, str) {
        //console.log("matched-",matched, "p1-",p1, "p2-",p2, "p3-",p3, "offset-",offset, "str-",str);       
        return "";
    }

    const postTextView = (post) => {
        if (post.Post_Text) {
            return (<>
                <div className='postText'><ReadMore>{post}</ReadMore></div>
            </>)
        } else {
            return (<></>)
        }
    }

    const videoClick = slideIndex => event => {
        //console.log("videoClick = ", slideIndex);
    };

    var beforeSlide = (slideIndex, postId, dt=1) => {
        // console.log("ViewPost-beforeSlide = ", slideIndex);
        // console.log("ViewPost-beforeSlide =postId= ", postId);

        //console.log(sliderRef.currentSlide);

        // explr-vid-view
        try {
            const videoBox = document.getElementById('explr-vid-view-'+dt+'-' + postId + '-' + slideIndex);
            //console.log(videoBox);
            if (videoBox) {
                var cPlayer = videojs('explr-vid-pl-' + postId + '-' + slideIndex);
                //console.log(cPlayer);
                if(cPlayer && !cPlayer.paused())
                {
                    cPlayer.pause();
                }
            }        
        } catch (err) {
            console.error(`beforeSlide-Error: ${err}`);
        }
    }

    var afterSlide = (slideIndex, postId, dt=1) => {
        // console.log("ViewPost-afterSlide = ", slideIndex);
        // console.log("ViewPost-afterSlide =postId= ", postId);
        //console.log(sliderRef.currentSlide);

        if(slideIndex>(postSlideMaxModel-1))
        {
            
            if(dontDismissModelHide.current)
                CenterModalOpen("block");
            else
                dontDismissModalClose();
        }
    }

    var boxSizeCalculations = (postMedia, dt=1) => {
        var mediaRatio, mediaWidth, mediaHeight; 
        mediaRatio = 1;
        var displayBoxWidth = width;

        //console.log('width', width , 'height', height, 'maxBoxWidth1024', maxBoxWidth1024, 'maxBoxWidth426', maxBoxWidth426, 'maxBoxWidth', maxBoxWidth);
 
        if (width == maxBoxWidth1024)
        {
            displayBoxWidth = maxBoxWidth1024 / 3 - 5;
        }
        else if (width > maxBoxWidth426)
        {
            displayBoxWidth = maxBoxWidth;
        }
        else
        {
            if(dt == 1)
                displayBoxWidth = displayBoxWidth - maxBoxPadding;
        }
        var displayBoxHeight = displayBoxWidth * mediaRatio;

        // if (postMedia.length == 0) 
        // {
        //     // no calc
        // }
        // else 
        if (postMedia.length == 1) 
        {
            // landscape
            
            // height={imageData.Media_Height} width={imageData.Media_Width} 
            postMedia.map((media, i) => {
                
                mediaWidth = parseFloat(media.Media_Width);
                mediaHeight = parseFloat(media.Media_Height);                
                
            });

            // if(mediaWidth > mediaHeight)
            // {
            //     mediaRatio = mediaHeight / mediaWidth;
            //     displayBoxHeight = displayBoxWidth * mediaRatio;
            // }

            //console.log({'mediaRatio':mediaRatio,'mediaWidth':mediaWidth, 'height':mediaHeight});

            //if (width < maxBoxWidth)
            //displayBoxHeight = displayBoxWidth * mediaRatio;

            mediaRatio = mediaHeight / mediaWidth;
            var newHeight = displayBoxWidth * mediaRatio;
            var maxHeight = displayBoxWidth;
            if(newHeight > maxHeight)
            {
                newHeight = maxHeight;
            }

            displayBoxHeight = newHeight;
        }
        else
        {
            //displayBoxHeight = displayBoxWidth * mediaRatio;
            displayBoxHeight = displayBoxWidth;
        }

        if(dt == 2)
        {
            //displayBoxWidth = displayBoxWidth - maxBoxPaddingWidthFull;   
            //displayBoxHeight = height - maxBoxPaddingHeightFull; 

            displayBoxWidth = width;
            displayBoxHeight = height;

            if(width >= maxBoxWidth768)
            {
                var boxContainer = document.getElementsByClassName('top-div')[0]; 
                var offsetWidth = boxContainer.offsetWidth;
                displayBoxWidth = offsetWidth;
            }
        }       

        //console.log({'width':displayBoxWidth, 'height':displayBoxHeight});

        return {'width':displayBoxWidth, 'height':displayBoxHeight,'postMediaCount':postMedia.length};
    };


    // Media slider view image/video
    const mediaSlidView = (postMedia, post=null, dt=1) => {

        const postId = post.Post_ID?post.Post_ID:null;

        var displayDimensions = boxSizeCalculations(postMedia, dt);

        if(post.Post_Shared == "1")
        {
            displayDimensions.width = displayDimensions.width - 20;
        }

        var imageViewBox = "imageViewBox";
        var videoExplrView = "videoExplrView";
        
        // if(dt==2)
        // {
        //     imageViewBox = "imageViewBox225"; 
        //     videoExplrView = "videoExplrView225";
        // }

        if (postMedia.length == 1) {
            return (<>
                    {postMedia.map((val, i) => {
                        if (val.Media_Type == "0") {
                            if(dt==1)
                                imageViewBox = "imageViewBox3Single"; 
                            return (<>
                                <div className={imageViewBox} key={'explr-vid-view-1-'+postId+'-'+ i} style={{backgroundColor: val.Media_BG_Color, width:displayDimensions.width, height:displayDimensions.height}} onClick={() => onSetModalBox1({showModal:true,slideIndex:i,modalPost:post})} > 
                                    <CropImage key={'imageView-1-'+postId+'-'+i} postData={post} imageData={val} postId={postId} displayMode={dt} displayIndex={i} displayDimensions={displayDimensions} ></CropImage>
                                </div>
                            </>)
                        } else if (val.Media_Type == "2") {
                            return (<>
                                {ExplurgedVideoView(val)}
                                <div className={videoExplrView} key={'explr-vid-view-1-'+postId+'-'+ i} style={{backgroundColor: val.Media_BG_Color, width:displayDimensions.width, height:displayDimensions.height}} onClick={() => onSetModalBox1({showModal:true,slideIndex:i,modalPost:post})} > 
                                    <CropImage key={'imageView-1-'+postId+'-'+i} postData={post} imageData={val} postId={postId} displayMode={dt} displayIndex={i} displayDimensions={displayDimensions} ></CropImage>
                                </div>
                            </>)
                        }
                    })}
            </>)
        } else if (postMedia.length > 1) {
            
            const afterSlideConst = slideIndex => {
                afterSlide(slideIndex, postId);
            };
            const beforeSlideConst = slideIndex => {
                beforeSlide(slideIndex, postId);
            };
            return (<>
                <Carousel key={'crsl-1-'+postId} className='mediaSlider' afterSlide={afterSlideConst} beforeSlide={beforeSlideConst} enableKeyboardControls="true" swiping="true">
                    {postMedia.map((val, i) => {
                        if (val.Media_Type == "0") {
                            return (<>
                                <div className={imageViewBox} key={'explr-vid-view-1-'+postId+'-'+ i} style={{backgroundColor: val.Media_BG_Color, width:displayDimensions.width, height:displayDimensions.height}} onClick={() => onSetModalBox1({showModal:true,slideIndex:i,modalPost:post})} >                                
                                    <CropImage key={'imageView-1-'+postId+'-'+i} postData={post} imageData={val} postId={postId} displayMode={dt} displayIndex={i} displayDimensions={displayDimensions} ></CropImage>
                                </div>                                
                            </>)
                        } else if (val.Media_Type == "2") {
                            return (<>
                                {ExplurgedVideoView(val)}
                                <div className={videoExplrView} key={'explr-vid-view-1-'+postId+'-'+ i} id={'explr-vid-view-1-'+postId+'-'+i} style={{backgroundColor: val.Media_BG_Color, width:displayDimensions.width, height:displayDimensions.height}} onClick={() => onSetModalBox1({showModal:true,slideIndex:i,modalPost:post})} > 
                                    <CropImage key={'imageView-1-'+postId+'-'+i} postData={post} imageData={val} postId={postId} displayMode={dt} displayIndex={i} displayDimensions={displayDimensions} ></CropImage>
                                </div> 
                            </>)
                        }
                    })}
                </Carousel>
            </>)
        } else {
            return (<></>)
        }
    }

    // Media slider view image/video
    const mediaSlidViewFullView = (postMedia, post=null, dt=1, slideIndex=0) => {

        const postId = post.Post_ID?post.Post_ID:null;

        var displayDimensions = boxSizeCalculations(postMedia, dt);

        if(post.Post_Shared == "1")
        {
            displayDimensions.width = displayDimensions.width - 20;
        }

        var boxWidth = displayDimensions.width;

        if(dt == 2)
        {   
            boxWidth = "100%";
            if(width >= maxBoxWidth768 && width < maxBoxWidth1024)
            {
                var boxContainer = document.getElementsByClassName('top-div')[0]; 
                var offsetWidth = boxContainer.offsetWidth;
                boxWidth = offsetWidth;
            }
        }

        var imageViewBox = "imageViewBox";
        var videoExplrView = "videoExplrView";

        if (postMedia.length == 1) {
            return (<>
                    {postMedia.map((val, i) => {
                        if (val.Media_Type == "0") {
                            if(dt==1)
                                imageViewBox = "imageViewBox3Single"; 
                            return (<>
                                <div className={imageViewBox} key={'explr-vid-view-2-'+postId+'-'+ i} style={{backgroundColor: val.Media_BG_Color, width:boxWidth, height:displayDimensions.height}} > 
                                    <CropImage key={'imageView-2-'+postId+'-'+i} postData={post} imageData={val} postId={postId} displayMode={dt} displayIndex={i} slideIndex={slideIndex} displayDimensions={displayDimensions} ></CropImage>
                                </div>
                            </>)
                        } else if (val.Media_Type == "2") {
                            return (<>
                                {/* {ExplurgedVideoView(val)} */}
                                <div className={videoExplrView} key={'explr-vid-view-2-'+postId+'-'+ i} style={{backgroundColor: val.Media_BG_Color, width:boxWidth, height:displayDimensions.height}} > 
                                    <CropImage key={'imageView-2-'+postId+'-'+i} postData={post} imageData={val} postId={postId} displayMode={dt} displayIndex={i} slideIndex={slideIndex} displayDimensions={displayDimensions} ></CropImage>
                                </div>
                            </>)
                        }
                    })}
            </>)
        } else if (postMedia.length > 1) {
            
            const afterSlideConst = slideIndex => {
                afterSlide(slideIndex, postId, 2);
            };
            const beforeSlideConst = slideIndex => {
                beforeSlide(slideIndex, postId, 2);
            };
            return (<>
                <Carousel slideIndex={slideIndex} key={'crsl-2-'+postId} className='mediaSlider' afterSlide={afterSlideConst} beforeSlide={beforeSlideConst} enableKeyboardControls="true" swiping="true">
                    {postMedia.map((val, i) => {
                        if (val.Media_Type == "0") {
                            return (<>
                                <div className={imageViewBox} key={'explr-vid-view-2-'+postId+'-'+ i} style={{backgroundColor: val.Media_BG_Color, width:boxWidth, height:displayDimensions.height}} >                                
                                    <CropImage key={'imageView-2-'+postId+'-'+i} postData={post} imageData={val} postId={postId} displayMode={dt} displayIndex={i} slideIndex={slideIndex} displayDimensions={displayDimensions} ></CropImage>
                                </div>                                
                            </>)
                        } else if (val.Media_Type == "2") {
                            return (<>
                                {/* {ExplurgedVideoView(val)} */}
                                <div className={videoExplrView} key={'explr-vid-view-2-'+postId+'-'+ i} id={'explr-vid-view-2-'+postId+'-'+i} style={{backgroundColor: val.Media_BG_Color, width:boxWidth, height:displayDimensions.height}} > 
                                    <CropImage key={'imageView-2-'+postId+'-'+i} postData={post} imageData={val} postId={postId} displayMode={dt} displayIndex={i} slideIndex={slideIndex} displayDimensions={displayDimensions} ></CropImage>
                                </div>
                            </>)
                        }
                    })}
                </Carousel>
            </>)
        } else {
            return (<></>)
        }
    }    

    // Media slider view image/video
    const mediaSlidViewFullView2 = (postMedia, post=null, dt=1, slideIndex=0) => {
        //var displayDimensions = boxSizeCalculations(postMedia, dt);
        var imageViewBox = "imageViewBox";
        var backgroudColor = "#4a4b60";
        if (post.Post_Type == "0") { //Explurged In
            return (<>     
                <div className={imageViewBox}> 
                    <img className='imageView' src={post.Map_Place_Thumb_Photo} ></img>
                </div>              
            </>);            
        } else if (post.Post_Type == "2") { //Travelling to
            return (<>
                <div className={imageViewBox}> 
                    <img className='imageView' src={post.Map_Place_Thumb_Photo} ></img>
                </div>
            </>);            
        } else if (post.Post_Type == "3") { //Will go to              
            return (<>
                <div className={imageViewBox}> 
                    <img className='imageView' src={post.Map_Place_Thumb_Photo} ></img>
                </div>
            </>);             
        } else if (post.Post_Type == "9") { //Link Preview
            var previewMetadata = JSON.parse(post.preview_metadata);
            var previewImg = decodeURIComponent(previewMetadata.pre_meta_image);
            return (<>
                <div className={imageViewBox}> 
                    <img className='imageView' src={previewImg} ></img>
                </div>
            </>);
        } else {
            return (<></>);
        }
    }     

    function urlify(text) {        
        return text.replace(linkRegex, function(url) {

            let withProtocol = url
        
            if(!withProtocol.startsWith("http")) {
              withProtocol = "http://" + url
            }
           
            const newStr = `<a href="${withProtocol}" target="_blank" >${url}</a>`

            //return '<a href="' + url + '" target="_blank">' + url + '</a>';
            return newStr;
        })
    }

    function replaceNLWithBr(txt) {
        return txt.replaceAll(/\n/g, "<br />");
    }

    function hashify(text) {        
        return text.replaceAll(hasHashRegex, function(hash) {
           
            //var newStr = `<a href="javascript:false" style="color:#2d97f7" onclick="${clickCenterModalOpen}" >${hash}</a>`

            var newStr = `<span style="color:#2d97f7">${hash}</span>`;
            return newStr;
        });
    }

    function tagiify(text) {        
        return text.replaceAll(tagHashRegex, function(hash) {
           
            //var newStr = `<a href="javascript:false" style="color:#2d97f7" onclick="${clickCenterModalOpen}" >${hash}</a>`

            var newStr = `<span style="color:#2d97f7">${hash}</span>`;
            return newStr;
        });
    }    

    const ReadMore = ({ children }) => {
        var post = children;
        var Post_Text = decodeURIComponent(post.Post_Text);
        var text_length = 200;
        var mxL = 3;
        var tlns = Post_Text.match(regexNewLineCount).length;
        if(tlns > mxL)
        {
            // calculate length of 2 lines text
            // extract only 3 line
            var textLines = Post_Text.split("\n")
            var text2 = textLines[0] +"\n"+ textLines[1] +"\n"+ textLines[2] +"\n"+ textLines[3];
            var text2Length = text2.length;
            if(text_length > text2Length)
            {
                text_length = text2Length;
            }
        }

        Post_Text = replaceNLWithBr(Post_Text);
        Post_Text = hashify(Post_Text);
        Post_Text = tagiify(Post_Text);
        Post_Text = urlify(Post_Text);
        
        if (Post_Text.length > 300 && post.Post_Type == constants.POST_TYPE_WITH_SAY_STR) // 5
        {            
            text_length = 300;
        }        
        let text = Post_Text;  
              
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };

        if (text.length > text_length || tlns > mxL) {
            return (
                <p className="text">
                    {isReadMore ? ReactHtmlParser(text.slice(0, text_length)) : ReactHtmlParser(text)}
                    <span
                        onClick={clickCenterModalOpen}
                        className="read-or-hide"
                        style={{ color: "blue" }}
                    >
                        {isReadMore ? "...read more" : " show less"}
                    </span>
                </p>
            );
        } else {
            return (<>{ReactHtmlParser(text)}</>)
        }
    };

    var ExplurgedVideoViewConversion = (videoViewCount) => {
        //var k = videoViewCount / 1000;
        return (<>{videoViewCount}</>)
    };    
    
    var ExplurgedVideoView = (data) => {    
        var Media_View_Count = data.Media_View_Count;
        // MEDIA_TYPE_VIDEO == 2 == Media_Type
        if (data.Media_Type==constants.MEDIA_TYPE_VIDEO && Media_View_Count > 0) {
            return (<>
                <div className='videoViewCount'>
                    <img className='videoViewCount_Icon' src={eyeFillIcon1x}></img>
                    {ExplurgedVideoViewConversion(Media_View_Count)}
                </div>
            </>)
        } else {
            return (<>
                {/* <div className='videoViewCount'>
                    <img className='videoViewCount_Icon' src={eyeFillIcon1x}></img>
                    9998K
                </div> */}
            </>)
        }
    }
    var ExplurgedLevelIconView = (data) => {
        var Explurged_Level = parseInt(data.Total_Explurged_Level);
        if (Explurged_Level < 20000) { // Cobalt
            return (<><img className='Level_Icon' src={cobaltLevelIcon}></img></>)
        } else if (Explurged_Level < 50000) { // Silver
            return (<><img className='Level_Icon' src={silverLevelIcon}></img></>)
        } else if (Explurged_Level < 100000) { // GOLD
            return (<><img className='Level_Icon' src={goldLevelIcon}></img></>)
        } else if (Explurged_Level < 500000) { // Spectra
            return (<><img className='Level_Icon' src={spectraLevelIcon}></img></>)
        } else { // Prime
            return (<><img className='Level_Icon' src={primeLevelIcon}></img></>)
        }
    }

    var mergeTitleNDesc = (title, desc) => {
        var str = "<b>" + title +"</b> " +desc;
        //console.log("mergeTitleNDesc-",title.length);
        //console.log(desc.length);
        //console.log(str.length);
        if(str.length > 70)
            str = str.substring(0, 70) + "....";
        return str;
        
    };


    const bucketUserPics = (post) => {
        var bucketUsers = post.LastExplurged_UserPath;
        if (bucketUsers.length > 0) {
            return (<>
                {bucketUsers.map((val, i) => {                        
                    return (<>
                        <img src={val.User_Profile_Path} alt="Avatar" className='kudosProfile'></img>
                    </>)                    
                })}
            </>)
        }
    }

    const bucketListTxt = (post) => {

        if(post.Bucket_Status=="2")
        {
            // get date Explurgedin_DateTime
            // Explurgedin_DateTime
            var BucketLabelText = post.BucketLabelText;
            var Explurgedin_DateTime = post.Explurgedin_DateTime;
            var timeText = postTimeViewBucket(Explurgedin_DateTime);
            // You Explurged-in {%DATE_TIME%}3
            BucketLabelText = BucketLabelText.replace("{%DATE_TIME%}",timeText + " ");
            return (<>
                    <div>
                        <div className="bucketUsers1">
                            {bucketUserPics(post)}
                        </div>
                        <div className="bucketTest">
                            <div className="btn-bucket-center">                                
                                {ReactHtmlParser(BucketLabelText)}
                            </div>
                        </div>
                    </div>                
            </>);
        }
        return (<>
            <div className="btn-bucket-center">
                Now add in your <b>Bucket List !</b>
            </div>
        </>);
    }

    const postMediaView = (post) => {
        var postMedia = post.Media_FilePath;

        if (post.Post_Type == "0") { //Explurged In
            return (<>
                <div className='explurgen-with-media'>

                    {ExplurgedLevelIconView(post)}

                    {mediaSlidView(postMedia, post)}

                    <div className="flex-parent">
                        <div className="Place_Photo_Box">
                            <CropImagePlacePhoto key={'place-photo-1-'+post.Post_ID} postData={post} postId={post.Post_ID} onSetModalBox2={onSetModalBox2} ></CropImagePlacePhoto>
                            {/* <img className='Place_Photo_Img' src={post.Map_Place_Thumb_Photo} onClick={() => setModalBox2({showModal:true,slideIndex:0,modalPost:post})} ></img> */}
                        </div>
                        <div className="Map_Photo_Box">
                            <img className='Map_Photo_Img' src={post.Wall_Post_Map_Url}></img>
                        </div>
                    </div>
                    <div className="box-3">
                        <div className="card_add">
                            <div className="col-sm-12">
                                <div className="btn-text-down text-center">
                                    {bucketListTxt(post)}
                                    <button className="btn btn-light float-end AddbucketListBtn" onClick={clickCenterModalOpen}> + Bucket List</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
        } else if (post.Post_Type == "1") { //Pictures/Videos
            return (<>
                {ExplurgedLevelIconView(post)}
                {mediaSlidView(postMedia,post)}
            </>)
        } else if (post.Post_Type == "2") { //Travelling to
            return (<>
                <div className='explurgen-with-media'>
                    {ExplurgedLevelIconView(post)}
                    {mediaSlidView(postMedia,post)}

                    <div className="flex-parent">                        
                        <div className="Map_Photo_Box">
                            <img className='Map_Photo_Img' src={post.Wall_Post_Map_Url}></img>
                        </div>
                        <div className="Place_Photo_Box">
                            <CropImagePlacePhoto key={'place-photo-2-'+post.Post_ID} postData={post} postId={post.Post_ID} onSetModalBox2={onSetModalBox2} ></CropImagePlacePhoto>
                            {/* <img className='Place_Photo_Img' src={post.Map_Place_Thumb_Photo} onClick={() => setModalBox2({showModal:true,slideIndex:0,modalPost:post})} ></img> */}
                        </div>
                    </div>
                    <div className="box-3">
                        <div className="card_add">
                            <div className="col-sm-12">
                                <div className="btn-text-down text-center">
                                    {bucketListTxt(post)}
                                    <button className="btn btn-light float-end AddbucketListBtn" onClick={clickCenterModalOpen}> + Bucket List</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>)
        } else if (post.Post_Type == "3") { //Will go to
            return (
                <>
                    {ExplurgedLevelIconView(post)}
                    {mediaSlidView(postMedia,post)}
                    <div className="flex-parent">
                        <div className="Map_Photo_Box">                       
                            <img className='Map_Photo_Img' src={post.Wall_Post_Map_Url}></img>
                        </div>
                        <div className="Place_Photo_Box">    
                            <CropImagePlacePhoto key={'place-photo-3-'+post.Post_ID} postData={post} postId={post.Post_ID} onSetModalBox2={onSetModalBox2} ></CropImagePlacePhoto>                        
                            {/* <img className='Place_Photo_Img' src={post.Map_Place_Thumb_Photo} onClick={() => setModalBox2({showModal:true,slideIndex:0,modalPost:post})} ></img> */}
                        </div>
                    </div>
                </>
            )
        } else if (post.Post_Type == "4") { //along with
            return (
                <>
                    <p>along with</p>
                </>
            )
        } else if (post.Post_Type == "5") { //Say
            return (<>
                {/* <p className='postText'>{decodeURIComponent(postData.Post_Text)}</p> */}
            </>)
        } else if (post.Post_Type == "6") { //journey so far
            return (<>
                {ExplurgedLevelIconView(post)}
                {mediaSlidView(postMedia,post)}
            </>)
        } else if (post.Post_Type == "7") { //Profile Picture

            var displayDimensions = boxSizeCalculations(postMedia[0], 1);

            if(post.Post_Shared == "1")
            {
                displayDimensions.width = displayDimensions.width - 20;
            }

            var profileURL = null;
            postMedia.map((val, i) => {
                profileURL = val.Wall_Media_Path
            })

            return (<div className=''>

                {/* {postMedia.map((val, i) => {
                    return (<>
                        <img className='profilePic' src={val.Wall_Media_Path}></img>
                    </>)
                })} */}                

                <div className="row profile-pic-post-row imageViewBox" style={{ backgroundImage: `url(`+profileURL+`)`, backgroundColor: postMedia[0].Media_BG_Color, width:displayDimensions.width, height:displayDimensions.height }} onClick={() => onSetModalBox1({showModal:true,slideIndex:0,modalPost:post})} >
                    <CropProfileImage key={'profile-1-'+post.Post_ID} postData={post} postMedia={postMedia} postId={post.Post_ID} displayDimensions={displayDimensions} ></CropProfileImage>
                </div>
                <div className="col-sm-12 profile-pic-post-row2 polygonBox" 
                    style={{ width:displayDimensions.width, height:displayDimensions.height }}
                    onClick={() => onSetModalBox1({showModal:true,slideIndex:0,modalPost:post})}
                    >
                    <svg viewBox="0 0 100 100" className="image_profile_post" width="100%" height="100%">
                        <defs>
                        <pattern id={"Simg3-" + post.Post_ID} patternUnits="userSpaceOnUse" width="100%" height="100%">
                                {postMedia.map((val, i) => {
                                    return (<>
                                    <image href={val.Wall_Media_Path} width="100%" height="100%"></image>
                                    </>)
                                })}
                            </pattern>
                        </defs>
                    <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={"url(#Simg3-" + post.Post_ID + ")"} className="strokclassPost">  </polygon>
                    </svg>
                </div>

            </div>)
        } else if (post.Post_Type == "8") { //Cover Picture

            var displayDimensions = boxSizeCalculations(postMedia, 1);
            if(post.Post_Shared == "1")
            {
                displayDimensions.width = displayDimensions.width - 20;
            }
            return (<>

                {postMedia.map((val, i) => {
                    return (<>
                        {/* <div className={imageViewBox} key={'explr-vid-view-'+postId+'-'+ i} style={{backgroundColor: val.Media_BG_Color, width:displayDimensions.width, height:displayDimensions.height}} >                                
                            <CropImage key={'imageView-'+postId+'-'+i} imageData={val} postId={postId} displayMode={dt} displayIndex={i} displayDimensions={displayDimensions} ></CropImage>
                        </div> */}
                        <img className='coverPic' height={val.Media_Height} width={val.Media_Width} src={val.Wall_Media_Path} style={{backgroundColor: val.Media_BG_Color, width:displayDimensions.width, height:displayDimensions.height}} onClick={() => onSetModalBox1({showModal:true,slideIndex:i,modalPost:post})} ></img>
                        {/* <CropImage key={'imageView-'+postId+'-'+i} imageData={val} postId={postId} displayMode={dt} displayIndex={i} displayDimensions={displayDimensions} ></CropImage> */}
                    </>)
                })
                }

            </>)
        } else if (post.Post_Type == "9") { //Link Preview
            // post.Post_Text
            var Post_Text = decodeURIComponent(post.Post_Text);
            var previewMetadata = JSON.parse(post.preview_metadata);
            var previewTitle = decodeURIComponent(previewMetadata.pre_meta_title);
            var previewUrl = decodeURIComponent(previewMetadata.pre_meta_url);
            var previewDesc = decodeURIComponent(previewMetadata.pre_meta_desc);
            var previewImg = decodeURIComponent(previewMetadata.pre_meta_image);

            //console.log("post.Post_Text-",Post_Text);

            var Post_Text = previewUrl.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im)[1].toUpperCase();
            //var Post_Text = ReactHtmlParser(previewUrl.replace(linkRegexHttpWww, replacerHttpWww).toUpperCase());

            //var titlenDesc = ReactHtmlParser(mergeTitleNDesc(previewTitle,previewDesc));

            return (<>
                <div className='row p0 linkPreviewRightBoxContainer'>
                    <div className='linkPreviewLeftBox'>
                        <img className='previewImg' src={previewImg} onClick={() => onSetModalBox2({showModal:true,slideIndex:0,modalPost:post})} ></img>
                    </div>
                    <div className='linkPreviewRightBox'>                    
                        <div className='linkPreviewRightBox2'>
                            <div className='linkPreviewRightBox3'>
                                {Post_Text}<br></br>
                                {/* <div className="a"><p className='previewTitle'></p></div> */}
                                {/* <div className="b"><p className='previewTitle'></p></div> */}
                                    <a className='previewLink' target='_blank' href={previewUrl}>
                                    
                                    {ReactHtmlParser(mergeTitleNDesc(previewTitle,previewDesc))}
                                    
                                    </a>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                
            </>)
        } else {
            return (<></>)
        }
    }

    const postFooterView = (post) => {
        //var kn = (post.Total_Post_Kudos_V1.length > 0) ? post.Total_Post_Kudos_V1 : 0;
        var kn = 0;
        if(post.Total_Post_Kudos_V1.length > 0 && post.Total_Post_Kudos_V1.length!="0")
        {
            if(post.Total_Post_Kudos_V1.length > 1)
            {
               kn = 11; // more than 9
            }
            else if(post.Total_Post_Kudos_V1.length == 1)
            {
                kn = parseInt(post.Total_Post_Kudos_V1);
            }
        }
        var i, kudoIcons = [], kudoURLs = [];
        for(i=0;i<3;i++){
            if(i<kn)
            {
                kudoIcons.push(KUDO_FILL_URL);
            }
            else
            {
                kudoIcons.push(KUDO_LINE_URL);
            }
            kudoURLs.push(KUDO_LINE_URL);
        }
        //var sn = (post.Total_Post_Says_V1 > 0) ? post.Total_Post_Says_V1 : 0;
        var sn = 0;
        if(post.Total_Post_Says_V1.length > 0 && post.Total_Post_Says_V1.length!="0")
        {
            if(post.Total_Post_Says_V1.length > 1)
            {
                sn = 11; // more than 9
            }
            else if(post.Total_Post_Says_V1.length == 1)
            {
                sn = parseInt(post.Total_Post_Says_V1);
            }
        }
        var text_says = "say";
        if(sn>1)
        {
            text_says = "says";
        }

        var psn = 0;
        if(post.Total_Post_Shares_V1.length > 0 && post.Total_Post_Shares_V1.length!="0")
        {
            if(post.Total_Post_Shares_V1.length > 1)
            {
                psn = 11; // more than 9
            }
            else if(post.Total_Post_Shares_V1.length == 1)
            {
                psn = parseInt(post.Total_Post_Shares_V1);
            }
        }

        if(kn == 0 && sn == 0 && post.Total_Post_Shares_V1 == 0)
        {
            return (<>
                <div className=" ">
                    <div className="row ">
                        <div className="col-sm-4 footer-left-inner1" onClick={clickCenterModalOpen}>
                            <span className="pull-left text-muted my-tool-tip kudoVal2">
                                {kudoURLs.map((kudoURL, i) => {
                                    return(<>
                                        <img src={kudoURL} className="kudimage" title="Kudos"></img>
                                    </>)                                    
                                })}
                                <br></br>
                                Kudos
                            </span>
                        </div>
                        <div className="col-sm-4 footer-left-inner2" onClick={clickCenterModalOpen}>
                            <span className="pull-left text-muted my-tool-tip">
                                <img src={SAY_URL} className="saysimage" title="Kudos"></img><br></br>
                                Say
                            </span>
                        </div>
                        <div className='col-sm-4 footer-left-inner3 grayscale' onClick={clickCenterModalOpen}>
                            <span className="pull-left text-muted my-tool-tip spreadVal2">
                                <img src={SPREAD_URL} className="spreadimage" title="Kudos"></img><br></br>
                                Spread
                            </span>
                        </div>
                    </div>
                </div>
            </>)
        }
        else
        {

            return (<>
                <div className=" side-upper">
                    <div className="row kudo-mark">
                        <div className="col-sm-6 footer-left-inner4 kudoVal" onClick={clickCenterModalOpen}>
                            <div className="kudo-count-container">   
                                <div className="kudo-user-names">
                                    {(kn > 0) ? kudosUserPics(post) : ""}
                                </div>
                                <div className="kudo-counts">
                                    {(kn > 0) ? kudosUserPics2(post.Total_Post_Kudos_V1,'kudos') : ""}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 footer-left-inner5 saysVal" onClick={clickCenterModalOpen}>                            
                            {(sn > 0) ? kudosUserPics2(post.Total_Post_Says_V1,text_says) : ""}
                            {(psn > 0) ? kudosUserPics3(post.Total_Post_Shares_V1, 'spread', sn) : ""}
                        </div>
                    </div>
                </div>
                <div className=" ">            
                    <div className=' mob_line_bottom'></div>
                </div>
                <div className=" ">
                    <div className="row ">
                        <div className="col-sm-4 footer-left-inner1" onClick={clickCenterModalOpen}>
                            <span className="pull-left text-muted my-tool-tip kudoVal2">
                                {kudoIcons.map((kudoURL, i) => {
                                    return(<>
                                        <img src={kudoURL} className="kudimage" title="Kudos"></img>
                                    </>)                                    
                                })}
                                <br></br>
                                Kudos
                            </span>
                        </div>
                        <div className="col-sm-4 footer-left-inner2" onClick={clickCenterModalOpen}>
                            <span className="pull-left text-muted my-tool-tip">
                                <img src={SAY_URL} className="saysimage" title="Kudos"></img><br></br>
                                Say
                            </span>
                        </div>
                        <div className='col-sm-4 footer-left-inner3 grayscale' onClick={clickCenterModalOpen}>
                            <span className="pull-left text-muted my-tool-tip spreadVal2">
                                <img src={SPREAD_URL} className="spreadimage" title="Kudos"></img><br></br>
                                Spread
                            </span>
                        </div>
                    </div>
                </div>
            </>)
        }
    }

    const kudosUserPics2 = (cnt, txt) => {
        return (<>
            {(cnt)}&nbsp;{(txt)}
        </>)
    }

    const kudosUserPics3 = (cnt, txt, sn=0) => {
        if(sn==0)
        {
            return (<>
                {(cnt)}&nbsp;{(txt)}
            </>)
        }
        else
        {
            return (<>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"></path>
                </svg>
                {(cnt)}&nbsp;{(txt)}
            </>)
        }
    }    

    const kudosUserPics = (post) => {
        var postKudos = post.Engagement_User_Paths;
        if (postKudos.length > 0) {
            var kun = 0;
            var uNames = [];

            var kn = 0;
            if(post.Total_Post_Kudos_V1.length > 0 && post.Total_Post_Kudos_V1.length!="0")
            {
                kn =post.Total_Post_Kudos_V1.length;  
                if(post.Total_Post_Kudos_V1.length > 1)
                {
                    kn = 11; // more than 9
                }
                else if(post.Total_Post_Kudos_V1.length == 1)
                {
                    kn = parseInt(post.Total_Post_Kudos_V1);
                }              
            }

            var kudosProfileClass = "kudosProfile";

            if(kn==2)
            {
                kudosProfileClass = "kudosProfile2";
            }
            else if(kn==11)
            {
                kudosProfileClass = "kudosProfile3";
            }

            return (<>
                {postKudos.map((val, i) => {
                    if (kun < 3 && !(uNames.includes(val.User_FirstName))) {
                        kun += 1;
                        uNames.push(val.User_FirstName);
                        return (<>
                            <img src={val.Kudos_User_Thumb_Pic} title={val.User_FirstName} alt="Avatar" className={kudosProfileClass} ></img>
                        </>)
                    }
                })}
            </>)
        }
    }

    const BottomModalOpen = (display) => {
        //console.log("BottomModalOpen ", display);
        var modelBox = document.getElementById('staticBackdropBox');
        modelBox.style.display = display;
        var model = document.getElementById('staticBackdrop');
        //console.log("BottomModalOpen ", model, display);
        if (model) {
            model.style.display = display;
        }
        if(display == "block")
        {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = "hidden";
        }
        else
        {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = "scroll";
        }
    }

    const modalClose = () => {
        var modelBox = document.getElementById('staticBackdropBox');
        modelBox.style.display = 'none';
        var model = document.getElementById('staticBackdrop'); 
        if (model) {
            model.style.display = 'none';

            // bottomModelHide.current = false;
            // setTimeout(()=>{
            //     bottomModelHide.current = true;
            // },timeoutBottomModel);

            setFullProfileInAppModalClose((fullProfileInAppModalClose + 1));

            //console.log('fullProfileInAppModalClose-', fullProfileInAppModalClose, postCountMaxModelTimes);

            if(fullProfileInAppModalClose >= (postCountMaxModelTimes-1))
            {
                setFullProfileInAppModal(false);
            }            
        }
        document.body.style.overflow = 'unset';
        document.documentElement.style.overflow = "scroll";
    }

    const dontDismissModalClose = () => {
        var modelBox = document.getElementById('dontMissModalBox');
        modelBox.style.display = 'none';
        var model = document.getElementById('dontMissModal');
        if (model) {
            model.style.display = 'none';

            dontDismissModelHide.current = false;
            //handleDataToParentDontDismissModelHide(false);
            setTimeout(()=>{
                dontDismissModelHide.current = true;
                //handleDataToParentDontDismissModelHide(true);
            },timeoutDontDismissModel);
        }
        document.body.style.overflow = 'unset';
        document.documentElement.style.overflow = "scroll";
    }    

    const clickCenterModalOpen = () => {
        CenterModalOpen("block");
    }

    const CenterModalOpen = (display) => {      
        //console.log("CenterModalOpen ", display);
        var modelBox = document.getElementById('dontMissModalBox');
        modelBox.style.display = display;
        var model = document.getElementById('dontMissModal');
        //console.log("CenterModalOpen ", model, display);
        if (model) {
            model.style.display = display;
        }
        if(display == "block")
        {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = "hidden";
        }
        else
        {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = "scroll";
        }        
    } 

    var touchSwipe = {};
    var touchMinDistance = 100; 
    var minYtouchDistance30 = 30;
    var maxXtouchDistance10 = 10;
    var minYtouchDistance50 = 50;       
    
    const _onTouchStart = (e) => {
        const touch = e.touches[0];
        touchSwipe = { x: touch.clientX,y: touch.clientY };
        //console.log("_onTouchStart-", touch, touchSwipe);
    }
    
    const  _onTouchMove = (e) => {
        const touch = e.changedTouches[0];
        //console.log("_onTouchMove-", touch, touchSwipe);
        if (e.changedTouches && e.changedTouches.length) {            
            touchSwipe.swiping = true;
        }
    }
    
    const  _onTouchEnd = (e,type) => {
        const touch = e.changedTouches[0];
        const absX = Math.abs(touch.clientX - touchSwipe.x);
        const absY = Math.abs(touch.clientY - touchSwipe.y);

        //console.log("_onTouchEnd-", touch, touchSwipe, absX, absY);

        if (touchSwipe.swiping) {
            //console.log("_onTouchEnd-1-", touch, touchSwipe);
            // check direction
            if(touch.clientY > touchSwipe.y)
            {
                if(
                    (absX == 0 && absY >= minYtouchDistance30) 
                    || (absX <= maxXtouchDistance10 && absY >= minYtouchDistance50) 
                    || (absY > touchMinDistance)
                )
                {
                    // touch down
                    //console.log("_onTouchEnd--2", touch, touchSwipe);                    
                    if(type == 2)
                    {
                        //setModalBox2({showModal:false,slideIndex:-1,modalPost:null});
                        onModelHide2();
                    }
                    else
                    {
                        //setModalBox({showModal:false,slideIndex:-1,modalPost:null});
                        onModelHide1();
                    }
                }
            }
        }
        touchSwipe = {};
    }   
    
    const  _onTouchEndBox1 = (e) => {
        _onTouchEnd(e,1);
    }
    const  _onTouchEndBox2 = (e) => {
        _onTouchEnd(e,2);
    }

    function PostInFullScreenVerticallyCenteredModal(props) {

        if(modalBox.modalPost == null)
        {
            return (<></>);
        }
        else
        {
            var post = modalBox.modalPost;
            var postMedia = post.Media_FilePath;
            return (<>
            <Modal
                {...props}
                
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="PostInFullScreenVerticallyCenteredModal"
            >
                <Modal.Header closeButton>
                {/* <img className='crossIcon' src={crossIcon3x} onClick={props.onHide}></img> */}
                {/* <Button onClick={props.onHide}><img className='crossIcon' src={crossIcon3x} onClick={props.onHide}></img></Button> */}
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title> */}
                </Modal.Header>
                <Modal.Body className='p0'>
                {/* <h4>Centered Modal</h4>
                <p>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
                </p> */}

                <div className='touch-on-popup'
                    onTouchStart={_onTouchStart} 
                    onTouchMove={_onTouchMove}
                    onTouchEnd={_onTouchEndBox1}
                            >
                    {mediaSlidViewFullView(postMedia,post,2,modalBox.slideIndex)}
                </div>

                </Modal.Body>
                {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
            </Modal>
            </>);
        }
    }

    function PostInFullScreenVerticallyCenteredModal2(props) {

        if(modalBox2.modalPost == null)
        {
            return (<></>);
        }
        else
        {
            var post = modalBox2.modalPost;
            var postMedia = post.Media_FilePath;

            var displayDimensions = boxSizeCalculations(postMedia, 2);
            var imageViewBox = "imageViewBoxFullScreen";
            var backgroudColor = "#4a4b60";

            if (post.Post_Type == "0") { //Explurged In

            } else if (post.Post_Type == "2") { //Travelling to

            } else if (post.Post_Type == "3") { //Will go to     

            } else if (post.Post_Type == "9") { //Link Preview

            } else {
                return (<></>);
            }    
            return (<>
            <Modal
                {...props}
                
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="PostInFullScreenVerticallyCenteredModal2"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='p0'>
                    <div className='touch-on-popup-2'
                        onTouchStart={_onTouchStart}
                        onTouchMove={_onTouchMove}
                        onTouchEnd={_onTouchEndBox2}
                                > 
                        <div className={imageViewBox} style={{backgroundColor:backgroudColor, width:"100%", height:displayDimensions.height}} >
                            {mediaSlidViewFullView2(postMedia,post,2,modalBox.slideIndex)}    
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            </>);
        }
    }

    var topPosition = null;

    const onModelEntered = () => {
        //console.log("onModelEntered----------------");
        // When the modal is shown, we want a fixed body

        //console.log("onModelEntered----------------", topPosition , window.scrollY);

        topPosition = window.scrollY;

        //console.log("onModelEntered----------------", topPosition , window.scrollY);

        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;        
    }

    const onModelExit = () => {
        //console.log("onModelExit----------------");

        // When the modal is hidden...
        //const scrollY = document.body.style.top;

        //console.log("onModelExit----------------", topPosition , scrollY, window.scrollY);

        document.body.style.position = '';
        document.body.style.top = '';
        //window.scrollTo(0, parseInt(topPosition || 0));

        window.scrollTo({top:parseInt(topPosition || 0)});

        //window.scrollTo(0, parseInt(scrollY || '0') * -1);
        //console.log("onModelExit----------------", topPosition , scrollY, window.scrollY);
    }
    

    const onModelExited1 = () => {
        //console.log("onModelExited1---------------1-");
        //onModelExit();
    }

    const onModelExited2 = () => {
        //console.log("onModelExited2---------------2-");
        //onModelExit();
    }
    

    const onModelEntered1 = () => {
        //console.log("onModelEntered1---------------1-");
        //onModelEntered();
    }

    const onModelEntered2 = () => {
        //console.log("onModelEntered2---------------2-");
        //onModelEntered();
    }

    const onModelShow1 = () => {
        //console.log("onModelShow1---------------1-");
    }

    const onModelShow2 = () => {
        //console.log("onModelShow2---------------2-");

    }

    const onModelHide1 = () => {

        //console.log("onModelHide1---------------1-");

        setModalBox({showModal:false,slideIndex:-1,modalPost:null});
        
        //onModelExit();
    }

    const onModelHide2 = () => {
        //console.log("onModelHide2---------------2-");


        setModalBox2({showModal:false,slideIndex:-1,modalPost:null});

        //onModelExit();
    }

    const onSetModalBox1 = (setModalBoxData) => {

        //console.log("onSetModalBox1---------------1-");

        setModalBox(setModalBoxData);
        //setModalBox({showModal:true,slideIndex:0,modalPost:post});
        //setModalBox({showModal:false,slideIndex:-1,modalPost:null})
    }

    const onSetModalBox2 = (setModalBoxData) => {
        //console.log("onSetModalBox2---------------2-");
        setModalBox2(setModalBoxData);
        //setModalBox2({showModal:true,slideIndex:0,modalPost:post});
        //setModalBox2({showModal:false,slideIndex:-1,modalPost:null})
    }    


    const normalPostOtherThanShared = (post, i) => {
        var btTxt = " ";
        var btTxtPT9 = " ";
        var postMedia = post.Media_FilePath;
        if(postMedia != undefined && postMedia.length == 0 )
        {
            btTxt = " bt ";

            if(post.Total_Post_Kudos_V1 != "0" || post.Total_Post_Says_V1 != "0" || post.Total_Post_Shares_V1 != "0")
            {
                btTxt = " ";
            }
        }
        else 
        {
            if(post.Post_Type == "9")
            {
                btTxtPT9 = " bbtm ";
            }
            else if(post.Total_Post_Kudos_V1 != "0" || post.Total_Post_Says_V1 != "0" || post.Total_Post_Shares_V1 != "0")
            {
                btTxt = " bbtm ";
            }            
        }
        if(post.Post_Type == "7")
        {
            btTxt = " ";
        }
        return (
            <>
                        <div className='row p0 p0170'>
                            {/* <div className='col-lg-4 p0'></div> */}
                            <div className='col p0'>
                                <div className={"post-"+(i+1) + "-"+ post.Post_ID+' row block-box p0 post-box'}>
                                    {/* {(i+1)} */}
                                    {/* <div className='col-sm-4'></div> */}
                                    <div className='col-sm-12 col-md-12 col-lg-12 justify-content-center postCard2'>
                                        <div className='box-1'>
                                            {postProfileView(post)}
                                            <br></br>
                                            <div className='postTextView'>
                                                <div className='col'>
                                                    {postTextView(post)}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-12 justify-content-center postCard p0 m10px00'>                                     
                                        <div className={btTxt + ' box-1 00000000000'}>
                                            {/* {ExplurgedLevelIconView(post)} */}
                                            <div className='postMedia'>
                                                <div className={btTxtPT9}>
                                                {postMediaView(post)}
                                                </div>
                                            </div>
                                            {/* <br></br> */}
                                            <div className='kudosView'>
                                                <div >
                                                {postFooterView(post)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-sm-4'></div> */}
                                </div> 
                            </div> 
                            {/* <div className='col-lg-4 p0'></div> */}
                        </div>            
            </>
        );
    } 
    const boxSharedPost = (post, i) => {
        var btTxt = " ";
        var btTxtPT9 = " ";
        var postMedia = post.Media_FilePath;
        if(postMedia != undefined && postMedia.length == 0 )
        {
            btTxt = " bt ";
        }
        else 
        {
            if(post.Total_Post_Kudos_V1 == "0" && post.Total_Post_Says_V1 == "0" && post.Total_Post_Shares_V1 == "0")
            {
                btTxt = " bt ";
            }            
        }       
        return (
            <>
                        <div className='row p0 p0170'>
                            {/* <div className='col-lg-4 p0'></div> */}
                            <div className='col p0'>
                                <div className={"post-"+(i+1) + "-"+ post.Post_ID+' row block-box p0 post-box'}>
                                    {/* {(i+1)} */}
                                    {/* <div className='col-sm-4'></div> */}
                                    <div className='col-sm-12 col-md-12 col-lg-12 justify-content-center postCard2'>
                                        <div className='box-1'>
                                            {postProfileView2(post,0)}
                                            {/* <br></br>
                                            <div className='postTextView'>
                                                <div className='col'>
                                                    {postTextView(post)}
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-12 justify-content-center postCard3 p0 m10px00 p010'>
                                        <div className='b1lg box-1'>
                                            <div className='BoxPostWoner'>
                                                {postProfileView2(post,1)}
                                                <br></br>
                                                <div className='postTextView'>
                                                    <div className='col'>
                                                        {postTextView(post)}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <br></br> */}
                                            {/* {ExplurgedLevelIconView(post)} */}
                                            <div className='postMedia BoxPostWoner2'>
                                                <div >
                                                {postMediaView(post)}
                                                </div>
                                            </div>
                                            {/* <br></br> */}
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-12 justify-content-center postCard3 p0 m10px00'>
                                        <div className={btTxt + ' box-1 1111111111111'}>                                            
                                            <div className='kudosView'>
                                                <div >
                                                {postFooterView(post)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-sm-4'></div> */}
                                </div> 
                            </div> 
                            {/* <div className='col-lg-4 p0'></div> */}
                        </div>              
            </>
        );
    }    


    //console.log("-------------------------------------------------------------");

    const noExplurgeYet = () => {
        if (!isActive && postData.length==0)
        {
            return (<>
                <div className='no_explurge_yet row p0 p0170'>
                    <div className='col col-sm-12 p0'>No Explurge / Post yet</div>
                    {/* <div className='col col-sm-12 p0 splashScreen'><img src={splashScreen}></img></div> */}
                </div>
            </>);
        }
        return (<></>);
    }

    const loaderBox = () => {
        if (isActive)
        {
            return (<>
                <div className='loaderbox'>
                    <HashLoader key="mainLoader" className='mainLoader'></HashLoader>
                </div>                
                {/* <div className='splashScreen'><img src={splashScreen}></img></div> */}
            </>);
        }
        return (<></>);
    }

    return (<>

            <PullToRefresh
                pullDownContent={<PullDownContent height = "100px" />}
                releaseContent={<ReleaseContent height = "100px" />}
                refreshContent={<RefreshContent height = "100px" />}
                pullDownThreshold={100}
                onRefresh={handleRefresh}
                triggerHeight={100}
                backgroundColor=''
                startInvisible={true}
            >            
                <div className='post-view-list'>         
                        <div className='loader text-center p0170'>
                            {isActive && loaderBox()}
                            {/* {isActive && <HashLoader key="mainLoader" className='mainLoader'></HashLoader>} */}
                        </div>

                        {postData.length>0 && postData.map((post, i) => { //post.Badge_Status ="1",
                            if(post.Post_Shared == "1")
                            {
                                return (<>
                                    {boxSharedPost(post, i)}
                                </>)
                            }
                            else
                            {
                                return (<>
                                    {normalPostOtherThanShared(post, i)}                                
                                </>)
                            }
                        })}     
                        
                        {noExplurgeYet()}
                </div> 
            </PullToRefresh>  

            <PostInFullScreenVerticallyCenteredModal
                show={modalBox.showModal}
                onHide={onModelHide1}     
                onShow={onModelShow1}     
                onEntered={onModelEntered1}  
                onExited={onModelExited1}
            ></PostInFullScreenVerticallyCenteredModal>        
            <PostInFullScreenVerticallyCenteredModal2
                show={modalBox2.showModal}
                onHide={onModelHide2}       
                onShow={onModelShow2}
                onEntered={onModelEntered2}
                onExited={onModelExited2}
            ></PostInFullScreenVerticallyCenteredModal2>    
    </>);
}

export default ViewPost;